import { AsyncPipe } from "@angular/common";
import { Component, inject } from "@angular/core";
import { MatButton } from "@angular/material/button";
import { MatSelectModule } from "@angular/material/select";
import { FilterSortCriteriaEnum } from "src/app/model/homeListFilter";
import { StickyOnScrollDirective } from "src/shared/directives/sticky-on-scroll.directive";
import { ListFilterService } from "src/shared/services/list-filter.service";
import { FilterButtonComponent } from "./filter-button/filter-button.component";

@Component({
  selector: "app-home-list-filter-bar",
  standalone: true,
  imports: [
    MatSelectModule,
    FilterButtonComponent,
    StickyOnScrollDirective,
    MatButton,
    AsyncPipe,
  ],
  templateUrl: "./home-list-filter-bar.component.html",
  styleUrl: "./home-list-filter-bar.component.scss",
})
export class HomeListFilterBarComponent {
  FilterTypes = FilterSortCriteriaEnum;

  filterService = inject(ListFilterService);
  filterChanged = this.filterService.filtersChanged();

  resetFilters() {
    this.filterService.resetFilters("ALL");
  }
}
