import {
  Component,
  HostListener,
  inject,
  input,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatIcon } from "@angular/material/icon";
import { derivedAsync } from "ngxtension/derived-async";
import { map } from "rxjs";
import { FilterSortCriteriaEnum } from "src/app/model/homeListFilter";
import { ListFilterService } from "src/shared/services/list-filter.service";
import { HomeListFilterComponent } from "../../home-list-filter/home-list-filter.component";

@Component({
  selector: "app-filter-button",
  standalone: true,
  imports: [MatIcon],
  templateUrl: "./filter-button.component.html",
  styleUrl: "./filter-button.component.scss",
})
export class FilterButtonComponent {
  @ViewChild("bottomSheetTemplate") bottomSheetTemplate!: TemplateRef<any>;

  buttonText = input.required<string>();
  filterType = input.required<FilterSortCriteriaEnum>();

  FilterTypes = FilterSortCriteriaEnum;

  bottomSheet = inject(MatBottomSheet);
  filterService = inject(ListFilterService);

  active = derivedAsync(() => {
    if (
      this.filterType() !== this.FilterTypes.Sort &&
      this.filterType() !== this.FilterTypes.MaxDistance
    ) {
      return this.filterService.filtersSubject$.pipe(
        map((filters) =>
          Object.values(filters[this.filterType()]).every((category: any) =>
            category.every((item: any) => item.selected === true)
          )
        )
      );
    } else if (this.filterType() === this.FilterTypes.MaxDistance) {
      return this.filterService.filtersSubject$.pipe(
        map(
          (filters) =>
            filters?.maxDistance ===
            this.filterService.initialFilterSortCriteria.maxDistance
        )
      );
    } else return false;
  });

  openFilter() {
    const scrollY = window.scrollY;
    const bottomSheet = this.bottomSheet.open(HomeListFilterComponent, {
      data: { filterType: this.filterType(), active: this.active() },
    });
    this.handleFrameDisplay(window.innerWidth, window.innerHeight);
    bottomSheet.afterDismissed().subscribe(() => window.scrollTo(0, scrollY));
  }

  @HostListener("window:resize", [
    "$event.target.innerWidth",
    "$event.target.innerHeight",
  ])
  handleFrameDisplay(width: number, height: number) {
    const usesFrame = width > 600;

    if (usesFrame) {
      const frame = document.querySelector(".frame") as HTMLElement;
      if (!frame) {
        return;
      }
      const sheet: HTMLElement | null = document.querySelector(
        ".mat-bottom-sheet-container"
      );

      const rect = frame.getBoundingClientRect();
      var frameWidth = rect.width;

      sheet?.style.setProperty("width", `${frameWidth}px`);
      sheet?.style.setProperty("min-width", `${frameWidth}px`);
    }
  }
}
