import {
  Directive,
  ElementRef,
  HostListener,
  inject,
  Renderer2,
} from "@angular/core";

@Directive({
  selector: "[appStickyOnScroll]",
  standalone: true,
})
export class StickyOnScrollDirective {
  private stickyOffset = 300;
  private isSticky = false;
  private originalOffsetTop = 750;

  el = inject(ElementRef);
  renderer = inject(Renderer2);

  @HostListener("window:scroll", [])
  onWindowScroll(): void {
    const scrollY = window.scrollY;

    if (
      scrollY > this.originalOffsetTop + this.stickyOffset &&
      !this.isSticky
    ) {
      this.isSticky = true;
      this.renderer.addClass(this.el.nativeElement, "sticky");
    } else if (scrollY + 570 <= this.originalOffsetTop && this.isSticky) {
      this.isSticky = false;
      this.renderer.removeClass(this.el.nativeElement, "sticky");
    }
  }
}
