<div class="filter-bar-container" appStickyOnScroll>
  <div class="filter-bar">
    <app-filter-button
      buttonText="Sortierung"
      [filterType]="FilterTypes.Sort"
    ></app-filter-button>
    <app-filter-button
      buttonText="Versorgungsart"
      [filterType]="FilterTypes.CareTypes"
    ></app-filter-button>
    <app-filter-button
      buttonText="Positionen"
      [filterType]="FilterTypes.Positions"
    ></app-filter-button>
    <app-filter-button
      buttonText="Maximale Distanz"
      [filterType]="FilterTypes.MaxDistance"
    ></app-filter-button>
    <button
      mat-button
      class="filter-reset"
      color="primary"
      (click)="resetFilters()"
      [disabled]="!(filterChanged | async)"
    >
      Filter zurücksetzen
    </button>
  </div>
</div>
