<div class="filter-wrapper">
  <button
    mat-button
    class="ml-auto"
    color="primary"
    (click)="resetFilters()"
    [disabled]="data.active"
  >
    Zurücksetzen
  </button>

  @if(data.filterType === FilterTypes.Sort) {
  <h3 class="mb-16">Sortierung</h3>

  <mat-button-toggle-group name="sort" (change)="onSelectionChangeSort($event)">
    <mat-button-toggle
      id="sort-button-filter"
      [value]="'fit'"
      [checked]="isSelectedSort('fit')"
      >Empfehlung</mat-button-toggle
    >
    <mat-button-toggle
      id="sort-button-distance"
      [value]="'distance'"
      [checked]="isSelectedSort('distance')"
      >Distanz</mat-button-toggle
    >
  </mat-button-toggle-group>
  } @if(data.filterType === FilterTypes.CareTypes) {

  <div id="parent-filter-caretype">
    <h3 class="mb-8">Versorgungsart</h3>

    @for (label of objectKeys(baseCareTypes); track label; let i = $index) {
    <span>
      <mat-checkbox
        color="primary"
        id="parent-checkbox-caretype"
        class="example-margin checkbox-parent"
        [checked]="getParentState('careTypes', label) == 'allSelected'"
        [indeterminate]="getParentState('careTypes', label) == 'indeterminate'"
        (change)="updateParent($event, 'careTypes', label)"
      >
        {{ label }}
      </mat-checkbox>
    </span>
    <span>
      <ul>
        @for (val of baseCareTypes[label]; track val; let i = $index) {
        <span style="padding-left: 24px; display: block">
          <mat-checkbox
            color="primary"
            id="child-checkbox-caretype"
            [checked]="isSelectedCareType(val.id)"
            (change)="updateChildFilter($event, 'careTypes', label, val.label)"
          >
            {{ val.label }}
          </mat-checkbox>
        </span>
        }
      </ul>
    </span>
    }
  </div>
  } @if(data.filterType === FilterTypes.Positions) {
  <div id="parent-filter-position">
    <h3 class="mb-8">Position</h3>
    @for (label of objectKeys(basePositions); track label; let i = $index) {
    <span>
      <mat-checkbox
        color="primary"
        id="parent-checkbox-position"
        class="example-margin checkbox-parent"
        [checked]="getParentState('positions', label) == 'allSelected'"
        [indeterminate]="getParentState('positions', label) == 'indeterminate'"
        (change)="updateParent($event, 'positions', label)"
        style="font-size: 24px"
      >
        {{ label }}
      </mat-checkbox>
    </span>
    <span>
      <ul>
        @for (val of basePositions[label]; track val; let i = $index) {
        <span style="padding-left: 24px">
          <mat-checkbox
            color="primary"
            id="child-checkbox-position"
            [checked]="isSelectedChild('positions', val.id)"
            (change)="updateChildFilter($event, 'positions', label, val.label)"
          >
            {{ val.label }}
          </mat-checkbox>
        </span>
        }
      </ul>
    </span>
    }
  </div>

  } @if(data.filterType === FilterTypes.MaxDistance) {
  <div id="parent-filter-distance">
    <h3 class="mb-8">Maximale Distanz</h3>
    <div class="column">
      <mat-slider
        id="distance-slider"
        [min]="0"
        [max]="getInitialMaxDistance()"
        discrete
        step="5"
        showTickMarks
        style="width: 90%"
      >
        <input
          [value]="this.currentFilterState?.maxDistance ?? 50"
          (dragEnd)="onDistanceChange($event)"
          matSliderThumb
        />
      </mat-slider>
      <div
        class="row"
        style="justify-content: space-between; padding-right: 12px"
      >
        <p>0 km</p>
        <p>{{ getInitialMaxDistance() }} km</p>
      </div>
    </div>
  </div>
  }

  <button class="show-results" mat-raised-button (click)="closeSheet()">
    Zeige {{ filteredCount() }} / {{ totalCount() }} Jobs
  </button>
</div>
