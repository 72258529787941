@if (hasError) { <app-somethingwrong />} @else {

<div class="main">
  <div class="top-header">
    <app-applicant-profile-avatar
      class="left-element"
      *ngIf="!isLoading"
      [applier]="matchApplier?.applier!"
      [extId]="applierExtId ?? ''"
    />
    <img
      src="../../assets/images/logo_full.svg"
      style="text-align: center; height: 35px; flex-grow: 1"
    />
    <div class="right-element" *ngIf="!isLoading">
      <app-help-button />
    </div>
  </div>
  <mat-tab-group
    [selectedIndex]="currentTab"
    (selectedTabChange)="onTabChange($event)"
    animationDuration="0ms"
    [disableRipple]="true"
  >
    <mat-tab label="Meine Jobs ({{ allMatches.length }})"> </mat-tab>
    <mat-tab
      label="Bewerbungen {{
        appliedList.length > 0 ? '(' + appliedList.length + ')' : ''
      }}"
      matRippleDisabled
    >
    </mat-tab>
  </mat-tab-group>
  <div class="progress-spinner-wrapper" *ngIf="isLoading">
    <mat-spinner diameter="36" />
  </div>
  <div
    style="
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: flex-start;
    "
  >
    @if ((topMatchesList.length + alternativesList.length == 0) && (currentTab
    == 0) && (!isLoading)) {
    <div
      class="column"
      style="
        justify-content: center;
        padding: 12px;
        align-items: center;
        flex: 1;
      "
    >
      <p style="font-weight: 600; font-size: 20px">
        Du hast leider keine Jobs, die deinen Filtern entsprechen.
      </p>
      <button mat-button color="primary" (click)="resetFilters()">
        Filter zurücksetzen
      </button>
    </div>
    }
    <section *ngIf="!isLoading && currentTab == 0" class="match-list">
      <div class="top-hint-wrapper" *ngIf="topMatchesList.length > 0">
        <app-match-list-hint-top />
        <div style="height: 0.75rem"></div>
      </div>

      @if(topMatchesList.length + alternativesList.length !== 0) {
      <app-home-list-filter-bar></app-home-list-filter-bar>
      }
      <ng-container *ngFor="let groupedMatch of topMatchesList">
        <app-match-card
          [groupedMatch]="groupedMatch"
          [applier]="matchApplier?.applier!"
        ></app-match-card>
      </ng-container>
      <app-match-list-hint *ngIf="alternativesList.length > 0" />
      <ng-container *ngFor="let groupedMatch of alternativesList">
        <app-match-card
          [groupedMatch]="groupedMatch"
          [applier]="matchApplier?.applier!"
        ></app-match-card>
      </ng-container>
    </section>
    <section *ngIf="!isLoading && currentTab == 1" class="applied-list">
      <app-match-applied-card
        *ngFor="let match of appliedList"
        [match]="match"
        [applier]="matchApplier?.applier!"
      ></app-match-applied-card>
      <div class="center" *ngIf="appliedList.length == 0">
        <p>Du hast bisher für keinen Job dein Interesse hinterlegt.</p>
      </div>
    </section>
  </div>

  <app-footer [showWhatsappButton]="true"></app-footer>
</div>

}
